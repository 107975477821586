import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import { PublicContentCard } from '../../components/PublicContentCard/PublicContentCard';
import { ReactComponent as VerifySuccess } from '../../assets/verify-success.svg';
import { ROUTE } from '../../config/routes';
import {
  useGetUserRegistrationRecordQuery,
  RegistrationLinkExpiredValidationError,
} from '../../services/graphql';
import {
  USER_REGISTRATION_ID,
  onboardingErrorLinkExpired,
  onboardingErrorGeneral,
} from '../../App.constants';
import { getEncryptedId } from '../../utils';
import { useTracking } from '../../hooks/useTracking';

import { ErrorPageTitleState } from './types';
import { Navbar } from '../../Layouts/GridLayout/PublicLayoutNavbar';
import {
  GridPageCenteredSection,
  GridSection,
} from '../../Layouts/GridLayout/styles';
import { Footer } from '../../Layouts/GridLayout/PublicLayoutFooter';

export const OnboardingVerifySuccess = () => {
  const { trackPageWithAppName, trackEvent } = useTracking();
  const navigate = useNavigate();
  const encryptedId = getEncryptedId();
  localStorage.setItem(USER_REGISTRATION_ID, encryptedId);

  useEffect(() => {
    trackPageWithAppName('Onboarding - verify-success');
  }, []);

  const { data, error } = useGetUserRegistrationRecordQuery({
    variables: { encryptedId },
    context: {
      isAnonymous: true,
    },
  });

  const validationError =
    data?.userRegistrationRecordAnon as RegistrationLinkExpiredValidationError;
  const isRegistrationLinkExpired =
    validationError?.__typename === 'RegistrationLinkExpiredValidationError';

  useEffect(() => {
    if (isRegistrationLinkExpired || error) {
      navigate(ROUTE.ONBOARDING_ERROR, {
        state: {
          title: error ? onboardingErrorGeneral : onboardingErrorLinkExpired,
        },
      } as ErrorPageTitleState);
    }
  }, [isRegistrationLinkExpired, error]);

  return (
    <>
      <Navbar />
      <GridPageCenteredSection>
        {' '}
        <PublicContentCard
          Hero={<VerifySuccess width="100%" height="100%" />}
          HeaderText={
            <FormattedMessage
              id="onboarding-verify-success.header"
              defaultMessage="Your email is verified!"
            />
          }
          TextDescription={[
            <FormattedMessage
              id="onboarding-verify-success.text-description"
              defaultMessage="Next, we’ll ask a few questions to help us personalise your experience of HealthyYou"
            />,
          ]}
          ctaVariant="primary"
          CtaText={
            <FormattedMessage
              id="onboarding-verify-success.cta-text"
              defaultMessage="Continue account setup"
            />
          }
          loadingState={false}
          ctaAction={() => {
            trackEvent('Continue account setup', 'click');
            navigate(ROUTE.ONBOARDING_PERSONAL_DETAILS);
          }}
        />
      </GridPageCenteredSection>
      <GridSection row={2} column={1} columnSpan={14}>
        <Footer />
      </GridSection>
    </>
  );
};
