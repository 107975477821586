import { useEffect } from 'react';

export interface ConditionalMetaTagProps {
  metaTag: Pick<HTMLMetaElement, 'name' | 'content'>;
  isEnabled: boolean;
}

export const useConditionalMetaTag = ({
  isEnabled,
  metaTag: { name, content },
}: ConditionalMetaTagProps) => {
  useEffect(() => {
    const existingMetaTag = document.querySelector(`meta[name=${name}]`);

    if (!existingMetaTag) {
      const newMetaTag = document.createElement('meta');
      newMetaTag.name = name;
      newMetaTag.content = content;
      document.head.appendChild(newMetaTag);
    } else if (!isEnabled) {
      document.head.removeChild(existingMetaTag);
    }
  }, [isEnabled]);
};
