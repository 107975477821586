import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Navigate } from 'react-router-dom';

import {
  Button,
  Checkbox,
  Col,
  colors,
  Grid,
  Text,
  GRID_BREAKPOINT,
  Portal,
  spacing,
  fontSizes,
  Stack,
  Spacer,
  localDateTimeToDateTimeWithOffsetConvertor,
} from '@bt-healthcare/ui-toolkit';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTracking } from '../../hooks/useTracking';
import { useLogout } from '../../hooks/useLogout';
import {
  useUpdateUserTermsAndConditionsMutation,
  TermsAndConditionsStatus,
} from '../../services/graphql';

import { TERMS_AND_CONDITIONS_VERSION } from '../../App.constants';
import { DeclineTerms } from '../../components/Modal/DeclineTerms';

import { Form, GradientBackground } from './styles';
import { Policy } from '../../components/Policy/Policy';
import { PolicyType } from '../../components/Policy/types';
import { schema, TermsAndConditionsFormValues } from './data';
import { useAuthUserProfile } from '../../hooks/useAuthUserProfile';

export const TermsAndConditions = () => {
  const [toggleDeclineModal, setToggleDeclineModal] = useState(false);
  const { trackPageWithAppName } = useTracking();
  const logout = useLogout();
  const { data: userProfileData, refetch } = useAuthUserProfile();
  const accepted =
    userProfileData?.userPatientProfileResults.user?.attributes
      .termsAndConditions?.decision === TermsAndConditionsStatus.Accepted;
  const [updateUserTermsAndConditions, { data, loading }] =
    useUpdateUserTermsAndConditionsMutation({});

  useEffect(() => {
    if (data) {
      refetch();
    }
  }, [data]);

  const {
    handleSubmit,
    register,
    formState: { isValid },
  } = useForm<TermsAndConditionsFormValues>({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      agreeTerms: false,
      agreeShareData: false,
    },
  });

  useEffect(() => {
    trackPageWithAppName('Terms and conditions - Accept');
  }, []);

  const onSubmit = handleSubmit(async () => {
    updateUserTermsAndConditions({
      variables: {
        input: {
          dateTime: new Date().toISOString(),
          decision: TermsAndConditionsStatus.Accepted,
          version: TERMS_AND_CONDITIONS_VERSION,
          dataSharingAcceptanceDateZoned:
            localDateTimeToDateTimeWithOffsetConvertor(),
        },
      },
    });
  });

  if (accepted) {
    return <Navigate to="/#/" />;
  }

  return (
    <Form onSubmit={onSubmit}>
      <Policy type={PolicyType.TERMS_AND_CONDITIONS} />
      <GradientBackground />
      <Grid
        style={{
          bottom: 0,
          position: 'sticky',
          width: '100%',
          padding: `${spacing.s6} 0`,
          gridAutoFlow: 'dense',
          backgroundColor: 'white',
          gap: 8,
        }}
      >
        <Col
          col={{
            [GRID_BREAKPOINT.small]: '1 / 5',
            [GRID_BREAKPOINT.medium]: '1 / 9',
            [GRID_BREAKPOINT.large]: '2 / 9',
          }}
        >
          <Stack space="s4" id="terms-and-conditions.controls">
            <Checkbox
              id="terms-and-conditions-agree-terms-checkbox"
              {...register('agreeTerms')}
              label={
                <Text color={colors.grey.grey09} fontSize={fontSizes.sm}>
                  <FormattedMessage
                    id="terms-and-conditions.checkbox.agreeTerms"
                    defaultMessage="I agree with the terms and conditions"
                  />
                </Text>
              }
            />
            <Checkbox
              id="terms-and-conditions-agree-share-data-checkbox"
              {...register('agreeShareData')}
              label={
                <Text color={colors.grey.grey09} fontSize={fontSizes.sm}>
                  <FormattedMessage
                    id="terms-and-conditions.checkbox.agreeShareData"
                    defaultMessage="I agree to share my health data and clinical measurements with HealthyYou in order to receive personalised recommendations"
                  />
                </Text>
              }
            />
            <Spacer size="s1" />
          </Stack>
        </Col>
        <Col
          col={{
            [GRID_BREAKPOINT.small]: '1 / 5',
            [GRID_BREAKPOINT.medium]: '1 / 5',
            [GRID_BREAKPOINT.large]: '9 / 10',
          }}
          order={{
            [GRID_BREAKPOINT.small]: '2',
            [GRID_BREAKPOINT.large]: '1',
          }}
        >
          <Button
            id="decline"
            variant="secondary"
            onClick={(event) => {
              event.preventDefault();
              setToggleDeclineModal(true);
            }}
            disabled={loading}
          >
            <FormattedMessage
              id="terms-and-conditions.button.decline"
              defaultMessage="Decline"
            />
          </Button>
        </Col>
        <Col
          col={{
            [GRID_BREAKPOINT.small]: '1 / 5',
            [GRID_BREAKPOINT.medium]: '5 / 9',
            [GRID_BREAKPOINT.large]: '10 / 11',
          }}
          order={{
            [GRID_BREAKPOINT.small]: '1',
            [GRID_BREAKPOINT.large]: '2',
          }}
        >
          <Button
            id="accept-terms-and-conditions"
            type="submit"
            disabled={!isValid}
          >
            <FormattedMessage
              id="terms-and-conditions.button.accept"
              defaultMessage="Accept"
            />
          </Button>
        </Col>
      </Grid>
      <Portal>
        <DeclineTerms
          modalOpen={toggleDeclineModal}
          handleClose={() => setToggleDeclineModal(false)}
          decline={logout}
        />
      </Portal>
    </Form>
  );
};
