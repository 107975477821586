import { useEffect } from 'react';

import { Link, useLocation } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import { colors, Notification, Text } from '@bt-healthcare/ui-toolkit';
import { ReactComponent as Email } from '../../assets/email.svg';
import { PublicContentCard } from '../../components/PublicContentCard/PublicContentCard';
import {
  useCreateUserRegistrationRecordMutation,
  UserRegistrationRecordInput,
} from '../../services/graphql';
import { useTracking } from '../../hooks/useTracking';

import { GetInTouchContainer } from './styles';
import {
  GridPageCenteredSection,
  GridSection,
} from '../../Layouts/GridLayout/styles';
import { Navbar } from '../../Layouts/GridLayout/PublicLayoutNavbar';
import { Footer } from '../../Layouts/GridLayout/PublicLayoutFooter';

export const OnboardingCheckEmail = () => {
  const { trackPageWithAppName, trackEvent } = useTracking();
  const location = useLocation();

  useEffect(() => {
    trackPageWithAppName('Onboarding - check-email');
    window.scrollTo(0, 0);
  }, []);

  const [createUserRegistrationRecord, { loading, error }] =
    useCreateUserRegistrationRecordMutation({ context: { isAnonymous: true } });

  const { primaryEmailAddress } =
    location.state || ({} as UserRegistrationRecordInput);

  const onResendConfirmation = () => {
    createUserRegistrationRecord({
      variables: {
        input: {
          primaryEmailAddress,
        },
      },
    });
  };

  return (
    <>
      <Navbar />
      <GridPageCenteredSection>
        <PublicContentCard
          Hero={<Email width="100%" height="100%" />}
          HeaderText={
            <FormattedMessage
              id="onboarding-check-email.header"
              defaultMessage="Check your email inbox to verify your email"
            />
          }
          ctaVariant="tertiary"
          CtaText={
            <FormattedMessage
              id="onboarding-check-email.resend-text"
              defaultMessage="Resend confirmation email"
            />
          }
          loadingState={loading}
          ctaAction={() => {
            onResendConfirmation();
            trackEvent('Resend Confirmation Mail', 'click');
          }}
        />
        {error && (
          <Notification type="warning">
            <FormattedMessage
              id="onboarding-check-email.resend-email-error"
              defaultMessage="Something went wrong, please try again."
            />
          </Notification>
        )}

        <GetInTouchContainer>
          <Text color={colors.grey.grey08}>
            <FormattedMessage
              id="onboarding-email-address.get-in-touch"
              defaultMessage="Having trouble? Get in touch"
            />
          </Text>
          <Link to="mailto:support.healthyyou@bt.com">
            <Text color={colors.primaryIndigo.indigo08}>
              support.healthyyou@bt.com
            </Text>
          </Link>
        </GetInTouchContainer>
      </GridPageCenteredSection>
      <GridSection row={2} column={1} columnSpan={14}>
        <Footer />
      </GridSection>
    </>
  );
};
